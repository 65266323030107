import { buildUrl } from "~/features/application/utilities";
import { wretch } from "~/lib/external/wretch";
import { type AbortableRequestOptions } from "~/types/application";

import { pathwaySchema } from "./pathway.schema";

const getUrl = (pathwayId: number) => buildUrl(`pathway/${pathwayId}`, 1);

const getPathway = (pathwayId: number, { signal }: AbortableRequestOptions) =>
	wretch()
		.headers({
			"Content-Type": "application/json",
		})
		.signal(signal)
		.napierNativeCache()
		.get(getUrl(pathwayId))
		.json(pathwaySchema.parseAsync);

type GetPathwayResponse = Awaited<ReturnType<typeof getPathway>>;

export { getPathway, getUrl, type GetPathwayResponse };
