import { buildUrl } from "~/features/application/utilities";
import { wretch } from "~/lib/external/wretch";
import { type AbortableRequestOptions, type SortDirection } from "~/types/application";

import { type CompletedLearningResponse, completedLearningSchema } from "./completed-learning.schema";

const getUrl = () => buildUrl("profile/completed", 1);

const getCompletedLearning = async (
	sort: SortDirection,
	{ signal }: AbortableRequestOptions,
): Promise<CompletedLearningResponse> =>
	wretch()
		.headers({
			"Content-Type": "application/json",
		})
		.signal(signal)
		.napierNativeCache()
		.query({ sort })
		.get(getUrl())
		.json(completedLearningSchema.parse);

type GetCompletedResponse = Awaited<ReturnType<typeof getCompletedLearning>>;

export { getCompletedLearning, getUrl as getCompletedLearningUrl, type GetCompletedResponse };
