import { buildUrl } from "~/features/application/utilities";
import { wretch } from "~/lib/external/wretch";
import { type AbortableRequestOptions } from "~/types/application";

import { type StageResponse, stageSchema } from "./stage.schema";

const getUrl = (stageId: number) => buildUrl(`stage/${stageId}`, 1);

const getStage = (stageId: number, { signal }: AbortableRequestOptions): Promise<StageResponse> =>
	wretch()
		.headers({
			"Content-Type": "application/json",
		})
		.signal(signal)
		.napierNativeCache()
		.get(getUrl(stageId))
		.json(stageSchema.parseAsync);

type GetStageResponse = Awaited<ReturnType<typeof getStage>>;

export { getStage, getUrl, type GetStageResponse };
