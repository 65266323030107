import { buildUrl } from "~/features/application/utilities";
import { wretch } from "~/lib/external/wretch";
import { type AbortableRequestOptions } from "~/types/application";

import { awardsResponseSchema, type AwardsResponse } from "./awards.schema";

const getUrl = () => buildUrl("awards", 1);

const getAwards = ({ signal }: AbortableRequestOptions): Promise<AwardsResponse> =>
	wretch()
		.headers({
			"Content-Type": "application/json",
		})
		.signal(signal)
		.napierNativeCache()
		.get(getUrl())
		.json(awardsResponseSchema.parse);

type GetAwardsResponse = Awaited<ReturnType<typeof getAwards>>;

export { getAwards, type GetAwardsResponse };
