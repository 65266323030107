import i18next from "i18next";

import { type Language } from "~/translations/types";

import { translationResourceLoader } from "./translation-resource-loader";

export const loadTranslationResources = async (locale: Language) => {
	if (i18next.hasResourceBundle(locale, "translation") && i18next.hasResourceBundle(locale, "zod")) {
		return;
	}

	const resourceMap = await translationResourceLoader(locale);

	for (const [namespace, translations] of resourceMap.entries()) {
		i18next.addResourceBundle(locale, namespace, translations, true, true);
	}
};
