import {
	queryOptions,
	hashKey,
	useQuery,
	useSuspenseQuery,
	useMutation,
	useQueryClient,
	type UseQueryOptions,
	type UseQueryResult,
	type UseSuspenseQueryOptions,
	type UseSuspenseQueryResult,
	type UseMutationOptions,
	type UseMutationResult,
	type Query,
} from "@tanstack/react-query";

import { getProfileQueryKey } from "~/features/profile/api";
import { DEFAULT_SORT_DIRECTION, DEFAULT_TITLE_SORT_KEY } from "~/features/profile/constants";
import { type SortDirection } from "~/types/application";
import { directionalStringsSort } from "~/utilities/directional-sort/index.";

import {
	getProfileInterests,
	postProfileInterests,
	type PostProfileInterestsResponse,
	type GetProfileInterestsResponse,
} from "./interests.api";
import { type ProfileInterestsResponse } from "./interests.schema";

const getQueryKey = (sort: SortDirection = DEFAULT_SORT_DIRECTION) => ["profile", "interests", { sort }] as const;

const getBaseQueryOptions = <TData = GetProfileInterestsResponse>() =>
	queryOptions<GetProfileInterestsResponse, Error, TData>({
		queryFn: ({ signal }) => getProfileInterests({ signal }),
		queryKey: getQueryKey(),
	});

const useProfileInterestsQuery = <TData = GetProfileInterestsResponse>(
	sortKey: "title" = DEFAULT_TITLE_SORT_KEY,
	sort: SortDirection = DEFAULT_SORT_DIRECTION,
	options?: Pick<UseQueryOptions<GetProfileInterestsResponse, Error, TData>, "select">,
): UseQueryResult<TData, Error> =>
	useQuery<GetProfileInterestsResponse, Error, TData>({
		...options,
		...getBaseQueryOptions(),
		select: (data): TData => directionalStringsSort(sort, structuredClone(data), sortKey) as TData,
	});

const useProfileInterestsSuspenseQuery = <TData = GetProfileInterestsResponse>(
	sortKey: "title" = DEFAULT_TITLE_SORT_KEY,
	sort: SortDirection = DEFAULT_SORT_DIRECTION,
	options?: Pick<UseSuspenseQueryOptions<GetProfileInterestsResponse, Error, TData>, "select">,
): UseSuspenseQueryResult<TData, Error> =>
	useSuspenseQuery<GetProfileInterestsResponse, Error, TData>({
		...options,
		...getBaseQueryOptions(),
		select: (data) => directionalStringsSort(sort, structuredClone(data), sortKey) as TData,
	});

type PostProfileInterestsMutationOptions = Omit<
	UseMutationOptions<PostProfileInterestsResponse, Error, number[]>,
	"mutationFn" | "mutationKey"
>;

const usePostProfileInterestsMutation = (
	options?: PostProfileInterestsMutationOptions,
): UseMutationResult<PostProfileInterestsResponse, Error, number[]> => {
	const queryClient = useQueryClient();

	return useMutation<PostProfileInterestsResponse, Error, number[]>({
		mutationFn: (interestIds: number[]) => postProfileInterests(interestIds),
		mutationKey: getQueryKey(),
		...options,
		onSettled: async () => {
			queryClient.invalidateQueries({
				predicate: (query: Query) =>
					query.queryHash === hashKey(getQueryKey()) || query.queryHash === hashKey(getProfileQueryKey()),
				refetchType: "all",
				type: "all",
			});
		},
	});
};

export {
	useProfileInterestsQuery,
	useProfileInterestsSuspenseQuery,
	usePostProfileInterestsMutation,
	getBaseQueryOptions as getProfileInterestsBaseQueryOptions,
	getQueryKey as getProfileInterestsQueryKey,
	type ProfileInterestsResponse,
};
