import { buildUrl } from "~/features/application/utilities";
import { wretch } from "~/lib/external/wretch";
import { type AbortableRequestOptions } from "~/types/application";

import { type SiteConfigResponse, siteConfigSchema } from "./site-config.schema";

const getUrl = () => buildUrl("config", 1);

const getSiteConfig = ({ signal }: AbortableRequestOptions): Promise<SiteConfigResponse> =>
	wretch()
		.headers({
			"Content-Type": "application/json",
		})
		.signal(signal)
		.napierNativeCache()
		.get(getUrl())
		.json(siteConfigSchema.parseAsync);

type GetSiteConfigResponse = Awaited<ReturnType<typeof getSiteConfig>>;

export { getSiteConfig, type GetSiteConfigResponse };
