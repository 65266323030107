import { NapierBridgeCommon } from "@evotix/napier-ui-common-native-bridge";
import { napierMiddlewareStack, napierNativeCacheAddon } from "@evotix/napier-ui-common-native-bridge/utilities";
import i18next from "i18next";
import baseWretch from "wretch";
import { formDataAddon, queryStringAddon } from "wretch/addons";

import { globalConfig } from "~/features/application/config";
import { NATIVE_HEADER_KEY, TENANT_KEY_HEADER_KEY } from "~/features/application/constants";
import { applicationContext } from "~/features/application/context";
import { ensureCookieDeletion } from "~/features/native/authentication";
import { router } from "~/features/routing";
import { signInRoute } from "~/features/routing/routes/public";
import { tenantRoute } from "~/features/routing/routes/tenant";

import { queryClient } from "../../queryClient";

import { abortAddon } from "./addons/abort";
import { bearerAuthAddon } from "./addons/bearerAuth";
import { getBearerToken } from "./utilities";

export const coreWretch = () =>
	baseWretch(globalConfig.get().endpointUrl)
		.middlewares(napierMiddlewareStack)
		.addon(queryStringAddon)
		.addon(formDataAddon)
		.addon(napierNativeCacheAddon)
		.addon(abortAddon())
		.addon(bearerAuthAddon)
		/** Required to ensure backend cookie is set correctly */
		.options({
			credentials: "include",
			headers: {
				[NATIVE_HEADER_KEY]: applicationContext.isNative().toString(),
			},
		});

export const wretch = () =>
	coreWretch()
		.headers({
			"Accept-Language": i18next.language,
			[TENANT_KEY_HEADER_KEY]: applicationContext.tenant,
		})
		/** Global handler for all 401 (unauthorised) error responses - should auto redirect to the sign-in page */
		.catcher(401, async (error) => {
			await NapierBridgeCommon.setActiveUser({ token: undefined, userId: null });

			await ensureCookieDeletion();

			// TODO: do we want to pop up something indicating to the user that there auth has expired?
			await router.navigate({ from: tenantRoute.to, ignoreBlocker: true, params: true, to: signInRoute.to });
			// Remove any data from the query cache that is not the site config
			queryClient.removeQueries({
				/* v8 ignore start */
				predicate: (query) => !query.queryKey.includes("site-config"),
				/* v8 ignore end*/
			});

			return error;
		})
		.bearerAuth(getBearerToken);

export type {
	Wretch,
	Config,
	ConfiguredMiddleware,
	FetchLike,
	Middleware,
	WretchResponseChain,
	WretchOptions,
	WretchErrorCallback,
	WretchResponse,
	WretchDeferredCallback,
	WretchAddon,
} from "wretch";

export { WretchError } from "wretch/resolver";
