import { type Language } from "~/translations/types";
import { getLanguageStorageKey } from "~/translations/utilities";

/**
 * Retrieves the language preference for the user from localStorage
 * @param userId The user identifier
 * @returns The language preference for the user
 * @example getUserLanguage('user123');
 * // Output: 'en-gb'
 */
const getUserLanguage = async (userId: number): Promise<Language | null> => {
	const userStorageKey = await getLanguageStorageKey(userId);

	const localStorageValue = localStorage.getItem(userStorageKey);

	if (!localStorageValue) return null;

	return localStorageValue as Language;
};

type GetUserLanguageResponse = Awaited<ReturnType<typeof getUserLanguage>>;

export { getUserLanguage, type GetUserLanguageResponse };
