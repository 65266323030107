import { buildUrl } from "~/features/application/utilities";
import { wretch } from "~/lib/external/wretch";
import { type AbortableRequestOptions } from "~/types/application";

import { missionSchema } from "./mission.schema";

const getUrl = (id: number) => buildUrl(`mission/${id}`, 1);

const getMission = (id: number, { signal }: AbortableRequestOptions) =>
	wretch()
		.headers({
			"Content-Type": "application/json",
		})
		.signal(signal)
		.napierNativeCache()
		.get(getUrl(id))
		.json(missionSchema.parseAsync);

type GetMissionResponse = Awaited<ReturnType<typeof getMission>>;

export { getMission, getUrl, type GetMissionResponse };
