import { buildUrl } from "~/features/application/utilities";
import { wretch } from "~/lib/external/wretch";
import { type AbortableRequestOptions } from "~/types/application";

import { pathwaysSchema } from "./pathways.schema";

const getUrl = () => buildUrl("pathways", 1);

const getPathways = ({ signal }: AbortableRequestOptions) =>
	wretch()
		.headers({
			"Content-Type": "application/json",
		})
		.signal(signal)
		.napierNativeCache()
		.get(getUrl())
		.json(pathwaysSchema.parseAsync);

type GetPathwaysResponse = Awaited<ReturnType<typeof getPathways>>;

export { getPathways, getUrl, type GetPathwaysResponse };
