import { globalConfig } from "~/features/application/config";
import { applicationContext } from "~/features/application/context";
import { type CheckPath } from "~/types/application";
import { generateHexUuid } from "~/utilities/generate-hex-uuid";

import { URL_BASE, LAST_ACTIVITY_STORAGE, SESSION_TIMEOUT, SESSION_STORAGE } from "../constants";

export const buildLegacyUrl = <const TPath extends string>(
	path: CheckPath<TPath> extends `Error: ${infer E}` ? E : TPath,
) => {
	const { protocol, host } = new URL(globalConfig.get().endpointUrl);
	const tenant = applicationContext.tenant;
	return `${protocol}//${tenant}.${host}${URL_BASE}/v2/${encodeURI(path)}` as const;
};

/**
 * Checks if the session is active based on the last activity timestamp stored in sessionStorage.
 * @returns - True if the session is active, false otherwise.
 * @example isSessionActive() => true
 */
export const isSessionActive = (): boolean => {
	const lastActivity = parseInt(sessionStorage.getItem(LAST_ACTIVITY_STORAGE) || "0", 10);
	return Date.now() - lastActivity <= SESSION_TIMEOUT;
};

/**
 * Retrieves the session ID from sessionStorage. If the session is active, the last activity timestamp is updated.
 * If the session is not active, a new session ID is generated and stored.
 * @returns - The session ID.
 * @example getSessionId() => "550e8400e29b41d4a716446655440000"
 */
export const getSessionId = (): string => {
	const now = Date.now();
	const sessionId = sessionStorage.getItem(SESSION_STORAGE);

	if (!isSessionActive() || !sessionId) {
		const newSessionId = generateHexUuid();
		sessionStorage.setItem(SESSION_STORAGE, newSessionId);
		sessionStorage.setItem(LAST_ACTIVITY_STORAGE, now.toString());
		return newSessionId;
	}

	sessionStorage.setItem(LAST_ACTIVITY_STORAGE, now.toString());
	return sessionId;
};
