import { useCallback } from "react";
import { IconCheck } from "@evotix/napier-ui-common-icons";
import { NapierBridgeCommon } from "@evotix/napier-ui-common-native-bridge";
import { notifications } from "@mantine/notifications";
import { hashKey, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { useTranslation } from "react-i18next";

import { clearSession } from "~/features/analytics";
import { useSignOutMutation } from "~/features/authentication/sign-out/api";
import { ensureCookieDeletion } from "~/features/native/authentication";
import { signInRoute } from "~/features/routing/routes/public";
import { getSiteConfigQueryKey } from "~/features/site-config";
import { useBroadcastChannel } from "~/lib/broadcast-channel";

type UseSignOutParams = {
	triggerBroadcastMessage: boolean;
};

export const useSignOut = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const { broadcastMessage } = useBroadcastChannel();

	const { mutateAsync, isPending } = useSignOutMutation();

	const signOut = useCallback(
		async ({ triggerBroadcastMessage }: UseSignOutParams) => {
			try {
				await mutateAsync();
			} catch {
				/**
				 * Swallow the error if the call fails, we can force the user out
				 */
			} finally {
				if (triggerBroadcastMessage) {
					broadcastMessage({ type: "sign-out" });
				}

				await NapierBridgeCommon.setActiveUser({ token: undefined, userId: null });

				/**
				 * Forcible remove the cookie
				 * Native apps can occasionally fail to remove the cookie from the set-cookie header on the mutation response
				 * This bricks the application, because next request to the server has an authorisation header of `Bearer deleted`
				 */
				await ensureCookieDeletion();
				clearSession();

				/**
				 * The order of these operations is important.
				 * We must remove all query first, then navigate to the sign-in page.
				 * This is to ensure we destroy all listeners and data before navigating away, to avoid potential data refetch's in components
				 */
				queryClient.removeQueries({
					predicate: ({ queryHash }) => queryHash !== hashKey(getSiteConfigQueryKey()),
					type: "all",
				});

				await navigate({ from: "/$tenant/", ignoreBlocker: true, params: true, to: signInRoute.to });

				notifications.clean();
				notifications.show({
					color: "success",
					icon: <IconCheck />,
					message: t("modals.log-out.notifications.success"),
					title: t("global.success"),
				});
			}
		},
		[broadcastMessage, mutateAsync, navigate, queryClient, t],
	);

	return {
		isSigningOut: isPending,
		signOut,
	};
};
