import {
	type UseSuspenseQueryOptions,
	type UseSuspenseQueryResult,
	queryOptions,
	useQuery as rqUseQuery,
	useSuspenseQuery as rqUseSuspenseQuery,
	type UseQueryResult,
	type UseQueryOptions,
} from "@tanstack/react-query";

import { PostType } from "~/enums";
import { LIVE_QUERY_STALE_TIME } from "~/features/application/constants";
import { type Parent } from "~/types/application";

import { getBaseLearningQueryKey } from "../../shared.schema";

import { getMission, getUrl, type GetMissionResponse } from "./mission.api";

type MissionParent = Parent<PostType.MISSION, typeof getBaseQueryOptions, typeof getUrl>;
type Tuple = [misson: MissionParent];

const getBaseQueryKey = () => [...getBaseLearningQueryKey(), "mission"] as const;
const getQueryKey = (id: number) => [...getBaseQueryKey(), { id }] as const;

const getBaseQueryOptions = <TData = Extract<GetMissionResponse, { type: PostType.MISSION }>>(id: number) =>
	queryOptions<GetMissionResponse, Error, TData>({
		queryFn: ({ signal }) => getMission(id, { signal }),
		queryKey: getQueryKey(id),
	});

const useQuery = <TData = Extract<GetMissionResponse, { type: PostType.MISSION }>>(
	id: number,
	options?: Pick<UseQueryOptions<GetMissionResponse, Error, TData>, "select">,
): UseQueryResult<TData, Error> =>
	rqUseQuery<GetMissionResponse, Error, TData>({
		...getBaseQueryOptions(id),
		...options,
	});

const useSuspenseQuery = <TData = Extract<GetMissionResponse, { type: PostType.MISSION }>>(
	id: number,
	options?: Pick<UseSuspenseQueryOptions<GetMissionResponse, Error, TData>, "select" | "refetchOnWindowFocus">,
): UseSuspenseQueryResult<TData, Error> =>
	rqUseSuspenseQuery<GetMissionResponse, Error, TData>({
		...getBaseQueryOptions(id),
		...options,
		staleTime: LIVE_QUERY_STALE_TIME,
	});

const getParent = (id: number) =>
	({
		getQueryOptions: getBaseQueryOptions,
		getUrl,
		id,
		type: PostType.MISSION,
	}) satisfies MissionParent;

export {
	useQuery as useMissionQuery,
	useSuspenseQuery as useMissionSuspenseQuery,
	getBaseQueryOptions as getMissionBaseQueryOptions,
	getQueryKey as getMissionQueryKey,
	getBaseQueryKey as getMissionBaseQueryKey,
	getParent as getMissionParent,
	getUrl as getMissionUrl,
	type Tuple as MissionTuple,
};
