import { buildUrl } from "~/features/application/utilities";
import { wretch } from "~/lib/external/wretch";
import { type MutationSuccessResponse, mutationSuccessResponse } from "~/lib/schemas/mutation-success";
import { type AbortableRequestOptions } from "~/types/application";

import { type ProfileInterestsResponse, profileInterestsSchema } from "./interests.schema";

const getUrl = () => buildUrl("profile/interests", 1);

const getProfileInterests = ({ signal }: AbortableRequestOptions): Promise<ProfileInterestsResponse> =>
	wretch()
		.headers({
			"Content-Type": "application/json",
		})
		.signal(signal)
		.napierNativeCache()
		.get(getUrl())
		.json(profileInterestsSchema.parseAsync);

type GetProfileInterestsResponse = Awaited<ReturnType<typeof getProfileInterests>>;

const postProfileInterests = (interestIds: number[]): Promise<MutationSuccessResponse> =>
	wretch()
		.url(getUrl())
		.headers({
			"Content-Type": "application/json",
		})
		.post({ interests: interestIds })
		.json(mutationSuccessResponse.parseAsync);

type PostProfileInterestsResponse = Awaited<ReturnType<typeof postProfileInterests>>;

export {
	getUrl as getProfileInterestsUrl,
	getProfileInterests,
	postProfileInterests,
	type GetProfileInterestsResponse,
	type PostProfileInterestsResponse,
};
