import { buildUrl } from "~/features/application/utilities";
import { wretch } from "~/lib/external/wretch";
import { type AbortableRequestOptions } from "~/types/application";

import { type ProfileResponse, profileSchema } from "./profile.schema";

const getUrl = () => buildUrl("profile", 1);

const postUrl = () => buildUrl("profile/avatar", 1);

const getProfile = ({ signal }: AbortableRequestOptions): Promise<ProfileResponse> =>
	wretch()
		.headers({
			"Content-Type": "application/json",
		})
		.signal(signal)
		.napierNativeCache()
		.get(getUrl())
		.json(profileSchema.parseAsync);

type GetProfileResponse = Awaited<ReturnType<typeof getProfile>>;

const postUploadProfileAvatar = (file: File) =>
	wretch()
		.url(postUrl())
		.formData({
			file,
		})
		.post()
		.json(profileSchema.parseAsync);

type PostUploadProfileAvatarResponse = Awaited<ReturnType<typeof postUploadProfileAvatar>>;

export { getProfile, postUploadProfileAvatar, type GetProfileResponse, type PostUploadProfileAvatarResponse };
