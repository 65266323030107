import { type QueryClient } from "@tanstack/react-query";

import { LeaderboardKey } from "~/enums";

import { getActivitiesBaseQueryOptions } from "../api";
import { getCompletedLearningQueryOptions } from "../completed-learning/api";
import { getLeaderboardBaseQueryOptions } from "../leaderboard/api";

export const prefetchActivitiesData = (queryClient: QueryClient) => {
	queryClient.prefetchQuery(getActivitiesBaseQueryOptions());
	queryClient.prefetchQuery(getCompletedLearningQueryOptions());
	queryClient.prefetchQuery(getLeaderboardBaseQueryOptions(LeaderboardKey.MONTHLY));
	queryClient.prefetchQuery(getLeaderboardBaseQueryOptions(LeaderboardKey.OVERALL));
};
