import { NapierBridgeCommon } from "@evotix/napier-ui-common-native-bridge";
import { createRoute, lazyRouteComponent, redirect, useNavigate, Outlet } from "@tanstack/react-router";
import { z } from "zod";

import { getHeartbeatQueryKey } from "~/features/authentication/heartbeat/api";
import { getSiteConfigBaseQueryOptions } from "~/features/site-config";
import { useWindowListener } from "~/hooks/use-window-listener";
import { useBroadcastChannelListener } from "~/lib/broadcast-channel";

import { dashboardRoute } from "./global";
import { tenantRoute } from "./tenant";

export const publicRoute = createRoute({
	beforeLoad: async ({ context, params, search }) => {
		if (!context.auth) throw new Error("Auth context not found");

		if (await context.auth.isAuthenticated()) {
			throw redirect({
				params,
				to: search?.["redirect"] ?? dashboardRoute.to,
			});
		}
	},
	component: () => {
		const context = publicRoute.useRouteContext();
		const navigate = publicRoute.useNavigate();

		// eslint-disable-next-line react-hooks/rules-of-hooks -- we are technically in a component
		useBroadcastChannelListener("sign-in", async () => {
			await navigate({ ignoreBlocker: true, params: true, to: dashboardRoute.to });
		});

		// eslint-disable-next-line react-hooks/rules-of-hooks -- we are technically in a component
		useWindowListener("focus", async () => {
			await context.queryClient.invalidateQueries({
				queryKey: getHeartbeatQueryKey(),
				refetchType: "all",
				type: "all",
			});
		});

		return <Outlet />;
	},
	getParentRoute: () => tenantRoute,
	id: "public",
	onEnter: async () => {
		await NapierBridgeCommon.setStatusBarLight();
	},
	validateSearch: z.object({
		redirect: z.string().optional(),
	}),
});

export const authenticationLayout = createRoute({
	component: lazyRouteComponent(
		() => import("~/features/authentication/components/AuthenticationLayout"),
		"AuthenticationLayout",
	),
	getParentRoute: () => publicRoute,
	id: "authentication-layout",
});

export const signInRoute = createRoute({
	getParentRoute: () => authenticationLayout,
	notFoundComponent: () => {
		// eslint-disable-next-line react-hooks/rules-of-hooks -- This is a component, but eslint doesn't recognise it
		const navigate = useNavigate();

		navigate({
			params: signInRoute.useParams(),
			to: signInRoute.to,
		});

		return null;
	},
	path: "sign-in",
	staticData: {
		analyticsTitle: "Sign In",
	},
});

export const signInIndexRoute = createRoute({
	component: lazyRouteComponent(() => import("~/features/authentication/pages/SignIn"), "Page"),
	getParentRoute: () => signInRoute,
	path: "/",
});

export const signUpRoute = createRoute({
	getParentRoute: () => authenticationLayout,
	loaderDeps: ({ search }) => ({ search }),
	// eslint-disable-next-line sort-keys -- for some reason types break if loaderDeps appears after loader :(
	loader: async ({ params, context, deps }) => {
		const siteConfig = await context.queryClient.ensureQueryData(getSiteConfigBaseQueryOptions());

		if (siteConfig.canSignup === false) {
			throw redirect({
				params,
				search: {
					redirect: deps.search?.["redirect"],
				},
				to: signInRoute.to,
			});
		}
	},
	notFoundComponent: () => {
		// eslint-disable-next-line react-hooks/rules-of-hooks -- This is a component, but eslint doesn't recognise it
		const navigate = useNavigate();

		navigate({
			params: signUpRoute.useParams(),
			to: signUpRoute.to,
		});

		return null;
	},
	path: "sign-up",
	staticData: {
		analyticsTitle: "Sign Up",
	},
});

export const signUpIndexRoute = createRoute({
	component: lazyRouteComponent(() => import("~/features/authentication/pages/SignUp"), "Page"),
	getParentRoute: () => signUpRoute,
	path: "/",
});

export const forgotPasswordRoute = createRoute({
	getParentRoute: () => authenticationLayout,
	notFoundComponent: () => {
		// eslint-disable-next-line react-hooks/rules-of-hooks -- This is a component, but eslint doesn't recognise it
		const navigate = useNavigate();

		navigate({
			params: forgotPasswordRoute.useParams(),
			to: forgotPasswordRoute.to,
		});

		return null;
	},
	path: "forgot-password",
	staticData: {
		analyticsTitle: "Forgot Password",
	},
});

export const forgotPasswordIndexRoute = createRoute({
	component: lazyRouteComponent(() => import("~/features/authentication/pages/ForgotPassword"), "Page"),
	getParentRoute: () => forgotPasswordRoute,
	path: "/",
});

// TODO: decide the shape of this schema
const resetPasswordSearchSchema = z.object({ token: z.string().min(1) });

export const resetPasswordRoute = createRoute({
	beforeLoad: async ({ search, params }) => {
		const result = resetPasswordSearchSchema.safeParse(search);
		if (!result.success) {
			throw redirect({
				params,
				to: signInRoute.to,
			});
		}
	},
	getParentRoute: () => authenticationLayout,
	notFoundComponent: () => {
		// eslint-disable-next-line react-hooks/rules-of-hooks -- This is a component, but eslint doesn't recognise it
		const navigate = useNavigate();

		navigate({
			params: resetPasswordRoute.useParams(),
			search: resetPasswordRoute.useSearch(),
			to: resetPasswordRoute.to,
		});

		return null;
	},
	path: "reset-password",
	staticData: {
		analyticsTitle: "Password Reset",
	},
	validateSearch: resetPasswordSearchSchema,
});

export const resetPasswordIndexRoute = createRoute({
	component: lazyRouteComponent(() => import("~/features/authentication/pages/ResetPassword"), "Page"),
	getParentRoute: () => resetPasswordRoute,
	path: "/",
});
