import {
	queryOptions,
	useMutation,
	useQuery,
	useSuspenseQuery,
	type UseMutationOptions,
	type UseMutationResult,
	type UseQueryOptions,
	type UseQueryResult,
	type UseSuspenseQueryOptions,
	type UseSuspenseQueryResult,
} from "@tanstack/react-query";

import { LIVE_QUERY_STALE_TIME } from "~/features/application/constants";

import {
	getProfile,
	postUploadProfileAvatar,
	type GetProfileResponse,
	type PostUploadProfileAvatarResponse,
} from "./profile.api";
import { type ProfileResponse } from "./profile.schema";

const getQueryKey = () => ["profile"] as const;

const getBaseQueryOptions = <TData = GetProfileResponse>() =>
	queryOptions<GetProfileResponse, Error, TData>({
		queryFn: ({ signal }) => getProfile({ signal }),
		queryKey: getQueryKey(),
		refetchOnReconnect: true,
		refetchOnWindowFocus: true,
		staleTime: LIVE_QUERY_STALE_TIME,
	});

const useProfileQuery = <TData = GetProfileResponse>(
	options?: Pick<UseQueryOptions<GetProfileResponse, Error, TData>, "select">,
): UseQueryResult<TData, Error> =>
	useQuery<GetProfileResponse, Error, TData>({
		...options,
		...getBaseQueryOptions(),
	});

const useProfileSuspenseQuery = <TData = GetProfileResponse>(
	options?: Pick<UseSuspenseQueryOptions<GetProfileResponse, Error, TData>, "select">,
): UseSuspenseQueryResult<TData, Error> =>
	useSuspenseQuery<GetProfileResponse, Error, TData>({
		...options,
		...getBaseQueryOptions(),
	});

type UploadProfileAvatarMutationOptions = Omit<
	UseMutationOptions<PostUploadProfileAvatarResponse, Error, File, unknown>,
	"mutationFn" | "mutationKey"
>;

const MUTATION_KEY = ["profile", "photo", "upload"] as const;

const useUploadProfileAvatarMutation = (
	options?: UploadProfileAvatarMutationOptions,
): UseMutationResult<PostUploadProfileAvatarResponse, Error, File> =>
	useMutation<PostUploadProfileAvatarResponse, Error, File>({
		mutationFn: (file) => postUploadProfileAvatar(file),
		mutationKey: MUTATION_KEY,
		...options,
	});

export {
	useProfileQuery,
	useProfileSuspenseQuery,
	useUploadProfileAvatarMutation,
	getBaseQueryOptions as getProfileBaseQueryOptions,
	getQueryKey as getProfileQueryKey,
	type ProfileResponse,
};
