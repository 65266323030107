import { type CamelToSnakeCaseKeys } from "~/types/application";

/**
 * Converts a camelCase string to snake_case.
 * @param str - The string to convert.
 * @returns - The converted string.
 * @example camelToSnakeCase("camelCase"); // Returns "camel_case"
 */
export function camelToSnakeCase(str: string): string {
	return (
		str
			// Insert an underscore between lowercase and uppercase letters
			.replace(/(?<temp2>[a-z0-9])(?<temp1>[A-Z])/g, "$1_$2")
			// Handle cases where there are multiple uppercase letters in a row
			.replace(/(?<temp2>[A-Z]{2,})(?<temp1>[a-z0-9])/g, (_, p1, p2) => {
				return `${p1.slice(0, -1)}_${p1.slice(-1)}${p2}`;
			})
			.toLowerCase()
	);
}

/**
 * Converts all keys in an object from camelCase to snake_case.
 * @param obj - The object to convert.
 * @returns - The object with all keys converted to snake_case.
 * @example convertKeysToSnakeCase({ camelCaseKey: "value" }); // Returns { camel_case_key: "value" }
 */
export const convertKeysToSnakeCase = <T extends object>(obj: T): CamelToSnakeCaseKeys<T> => {
	const result = {} as CamelToSnakeCaseKeys<T>;
	for (const key in obj) {
		if (Object.prototype.hasOwnProperty.call(obj, key)) {
			const snakeKey = camelToSnakeCase(key);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any -- temporary type safety bypass
			(result as any)[snakeKey] = obj[key];
		}
	}
	return result;
};
